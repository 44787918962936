'use client';

import 'react-toastify/dist/ReactToastify.css';

import { enUS } from '@clerk/localizations';
import { ClerkProvider, useAuth, useUser } from '@clerk/nextjs';
import { NuqsAdapter } from 'nuqs/adapters/next/app';
import type { ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';

import { Route } from '@/constants/routes';

import { CSPostHogProvider } from './PostHogProvider';
import { ReactQueryProvider } from './ReactQueryProvider';

interface ProvidersProps {
  children: ReactNode;
}

function PostHogWrapper({ children }: { children: ReactNode }) {
  const { userId, orgId } = useAuth();
  const { user } = useUser();

  const username =
    user?.emailAddresses.find(
      (email) => email.verification?.status === 'verified',
    )?.emailAddress ||
    user?.emailAddresses[0]?.emailAddress ||
    'anonymous';

  const name = user
    ? `${user.firstName} ${user.lastName}`.trim()
    : 'Name not set';

  return (
    <CSPostHogProvider
      username={username}
      organizationId={orgId ?? undefined}
      name={name}
      userId={userId ?? 'anonymous'}
    >
      {children}
    </CSPostHogProvider>
  );
}

export function Providers({ children }: ProvidersProps) {
  return (
    <ClerkProvider
      localization={enUS}
      signInUrl={Route.SignIn}
      signUpUrl={Route.SignUp}
      signInFallbackRedirectUrl={Route.Dashboard}
      signUpFallbackRedirectUrl={Route.Welcome}
    >
      <ReactQueryProvider>
        <PostHogWrapper>
          <NuqsAdapter>
            {children}
            <ToastContainer
              position="bottom-right"
              newestOnTop={false}
              closeOnClick
              draggable
              pauseOnHover
            />
          </NuqsAdapter>
        </PostHogWrapper>
      </ReactQueryProvider>
    </ClerkProvider>
  );
}
